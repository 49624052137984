import React from 'react';

// mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

export const VisibilityOptions = {
    VISIBLE_ON_MODE: {
        key: 'show',
        displayName: 'Visible on Mode (and visible on normal form)'
    },
    HIDDEN_ON_MODE: {
        key: 'hide',
        displayName: 'Hidden on Mode (but visible on normal form)'
    },
    ONLY_VISIBLE_ON_MODE: {
        key: 'only',
        displayName: 'Only Visible on Mode (hidden on normal form)'
    },
    RECEIPT_INFO: {
        key: 'receiptInfo',
        displayName: 'Show in Receipt Info'
    },
    SIGNATURE_TABLE: {
        key: 'signatureTable',
        displayName: 'Show in Signature Table'
    },
    ADMIN_AREA: {
        key: 'admin',
        displayName: 'Show Only in Admin Area'
    },
    UNCONFIGURED: {
        key: 'unconfigured',
        displayName: 'Default value for fields before this visibility option existed. You should not see this text in the UI!'
    },
};

const VisibleOnTable = ({ selected, updateValue, updateSamplingEventVisibility, samplingEvents, section }) => {

    const options = ['show', 'hide', 'only']

    const radioOptions = [VisibilityOptions.RECEIPT_INFO, VisibilityOptions.SIGNATURE_TABLE, VisibilityOptions.ADMIN_AREA];

    // Note: May want to update other sections besides admin receipts to show different options

    return section === 'admin-receive'

        ?

        <>
            < br />
            <Typography variant="h5" style={{ float: "Left" }}>Visibility</Typography>
            <br /><br />
            <Typography variant="p" style={{ float: "Left" }}>Determines where field will be visible after submission.</Typography>
            <br />
            <FormControl style={{ float: "Left" }}>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="select"
                    name="radio-buttons-group"
                    value={selected.visibility}
                    onChange={(e) => {
                        updateValue(e, "visibility", false, 'selectChange');
                    }}
                >
                    {radioOptions.map(x => <FormControlLabel key={'vis-opt-'+x.key} value={x.key} control={<Radio />} label={x.displayName} />)}
                </RadioGroup>
            </FormControl>
        </>

        :

        <TableContainer>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Form Mode</TableCell>
                        <TableCell align="center">Visible on Mode (and visible on normal form)</TableCell>
                        <TableCell align="center">Hidden on Mode (but visible on normal form)</TableCell>
                        <TableCell align="center">Only Visible on Mode (hidden on normal form)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            Sample Container Request
                        </TableCell>
                        {options.map(op => {
                            return (
                                <TableCell align="center" key={op + '-scr'}>
                                    <Checkbox
                                        style={{ margin: '0px', padding: '0px' }}
                                        checked={selected.viewOnMaterials === op}
                                        disabled={selected.viewOnMaterials === op}
                                        onChange={() => updateValue(false, "viewOnMaterials", op)}
                                    />
                                </TableCell>
                            )
                        })}
                    </TableRow>
                    {samplingEvents.map(se => {
                        const visible = selected.visibleOnSamplingEvents?.find(vos => vos.samplingEvent === se.samplingEventKey)?.value
                        return (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                key={se.samplingEventName}
                            >
                                <TableCell component="th" scope="row">
                                    {se.samplingEventName}
                                </TableCell>
                                {options.map(op => {
                                    return (
                                        <TableCell align="center" key={op + '-scr'}>
                                            <Checkbox
                                                style={{ margin: '0px', padding: '0px' }}
                                                checked={visible ? visible === op : op === 'show'}
                                                disabled={visible ? visible === op : op === 'show'}
                                                onChange={() => updateSamplingEventVisibility(op, se.samplingEventKey)}
                                            />
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>;
}

export default VisibleOnTable;
