import * as React from 'react';

// mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';

// utils
import "../../utils/styles/cocStyle.css"
import dateHandlers from '../../utils/functions/dateHandlers';

/**
 * Element which displays table of receipt signatures and associated data.
 * @param {*} userInput All submission data entered by user.
 * @param {boolean} samplingMode Indicates whether the first signature is a sampler.
 * @param {Array<{key: string, displayName: string}>} fields Array of field metadata for columns to show in form.
 * @returns 
 */
const ReceiptSignatures = ({ userInput, samplingMode, fields }) => {
  const signatures = userInput.signatures?.filter(s => s.type === 'receive').sort(function (a, b) { return new Date(a.dateTime) - new Date(b.dateTime) });
  const initialSig = signatures ? signatures[0] : false
  const additionalSignatures = signatures?.length > 1 ? signatures.slice(1, signatures.length) : false

  /** Columns to not auto-generate because they are handled manually. */
  const alreadyHandled = new Set([
    "tempurature",
    "cooling",
    "dateTime",
    "name"
  ]);

  /** Lookup of all columns to be displayed if signatures contain data for them. */
  const allVisibleFields = new Set(fields.map(x => x.key));

  /** Columns to auto-generate if signatures contain data for them. */
  const additionalColumns = fields.filter(x => !alreadyHandled.has(x.key));

  /** Keys of all fields in first signature. */
  const intialSigColumnKeys = new Set();
  if (initialSig) Object.keys(initialSig)?.forEach(k => intialSigColumnKeys.add(k));

  /** Keys of all fields in all signatures after the first. */
  const additionalSigColumnKeys = new Set();
  if (additionalSignatures) additionalSignatures.forEach(x => Object.keys(x).forEach(k => additionalSigColumnKeys.add(k)));

  /**
   * Determines whether a user-defined column should be visible in the table.
   * If in sampling mode, there will be two sets of headers so the column will only be shown if it is relevant to 
   *   the current set of signatures (the first signature (sampler) or the remaining signatures (non-sampler)).
   * If *not* in sampling mode, there is only one header row so the column will be shown show if it is relevant to *any* signature.
   * @param {*} key Key pertaining to column to show or hide.
   * @param {boolean} isFirstRow Indicates whether column applies to first row of signatures (the sampler if in sampling mode).
   * @returns true if column should be shown, otherwise false.
   */
  const showKey = (key, isFirstRow) => isFirstRow
    ? (intialSigColumnKeys.has(key) || (!samplingMode && additionalSigColumnKeys.has(key)))
    : (additionalSigColumnKeys.has(key) || (!samplingMode && intialSigColumnKeys.has(key)));

  const headerCellClassName = "signatureCellHeader";
  const dataCellClassName = "labUseCell";

  /**
   * Renders standard header cells with fixed order.
   * @param {boolean} isFirstRow Indicates whether this is the first header row.
   * @returns Set of <td> elements containing appropriate header cells.
   */
  const renderStandardHeaders = (isFirstRow) => <>
    {allVisibleFields.has('name') && <td className={headerCellClassName}>{(samplingMode && isFirstRow) ? 'Sampled By' : 'Received By'}</td>}
    <td className={headerCellClassName}>Date</td>
    <td className={headerCellClassName}>Time</td>
    {!(samplingMode && isFirstRow) && allVisibleFields.has('tempurature') && <td className={headerCellClassName}>Received Temp., °C</td>}
    {!(samplingMode && isFirstRow) && allVisibleFields.has('cooling') && <td className={headerCellClassName}>Evidence of Cooling?</td>}
  </>;

  /**
   * Renders header cells for user-defined columns.
   * @param {boolean} isFirstRow Indicates whether this is the first header row.
   * @returns Set of <td> elements containing appropriate header cells.
   */
  const renderAdditionalHeaders = (isFirstRow) => additionalColumns.map(x => showKey(x.key, isFirstRow) &&
    <td
      key={(isFirstRow ? 'initial' : 'additional') + 'SigAdditionalCol_' + x.key}
      className={headerCellClassName}>
      {x.displayName}
    </td>
  );

  /**
   * Renders standard data cells with fixed order and special formatting.
   * @param {boolean} isFirstRow Indicates whether this is the first data row.
   * @param {*} sig Signature to render data for.
   * @returns Set of <td> elements containing appropriate data cells.
   */
  const renderStandardCells = (isFirstRow, sig) => <>

    {allVisibleFields.has('name') && <td className={dataCellClassName}>{sig.name}</td>}

    <td className={dataCellClassName}>{dateHandlers.standardDate(sig.dateTime)}</td>
    <td className={dataCellClassName}>{dateHandlers.standardTime(sig.dateTime)}</td>

    {
      !(samplingMode && isFirstRow) && allVisibleFields.has('tempurature') &&
      <td className={dataCellClassName}>{sig.tempurature?.toString() === '0.00' || sig.tempurature === 0 || !sig.tempurature ? 'NA' : sig.tempurature}</td>
    }

    {
      !(samplingMode && isFirstRow) && allVisibleFields.has('cooling') &&
      <td className={dataCellClassName}>{sig.cooling === '1' || sig.cooling === 'Yes' ? 'Yes' : 'No'}</td>
    }
  </>;

  /**
   * Renders data cells for user-defined columns.
   * @param {boolean} isFirstRow Indicates whether this is the first data row.
   * @param {*} sig Signature to render data for.
   * @param {*} index Index of signature.
   * @returns Set of <td> elements containing appropriate data cells.
   */
  const renderAdditionalCells = (isFirstRow, sig, index) => additionalColumns.map(x => showKey(x.key, isFirstRow) &&
    <td
      key={
        (isFirstRow ? 'initial' : 'additional') +
        'SigAdditionalCol_' +
        x.key +
        (
          index != null
            ? ('_' + index.toString())
            : ''
        )
      }
      className={dataCellClassName}>
      {sig[x.key] ?? "NA"}
    </td>
  )

  return (
    <TableContainer>
      {
        initialSig &&
        <Table>

          <TableHead>
            <TableRow>
              {renderStandardHeaders(true)}
              {renderAdditionalHeaders(true)}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              {renderStandardCells(true, initialSig)}
              {renderAdditionalCells(true, initialSig)}
            </TableRow>
            {
              samplingMode && additionalSignatures &&
              <TableRow>
                {renderStandardHeaders(false)}
                {renderAdditionalHeaders(false)}
              </TableRow>
            }
            {
              additionalSignatures && additionalSignatures.map((s, i) =>
                <TableRow key={'sig-add-' + i}>
                  {renderStandardCells(false, s)}
                  {renderAdditionalCells(false, s, i)}
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      }
    </TableContainer>

  );
}

export default ReceiptSignatures;
