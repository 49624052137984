import React from 'react';

// mui
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import { Button, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

const Required = ({ selected, updateValue, currentForm }) => {
    
    const [newKey, setNewKey] = React.useState(false)
    const [selKey, setSelKey] = React.useState(false)

    const toggleChecked = () => {
        if (selected.required?.key || newKey) {
            updateValue(false, 'required', false, 'delete')
            setNewKey(false)
        } else {
            updateValue(false, 'required', {key: true, value: []})            
        }
    }
    
    const handleSelection = (e) => {
        setSelKey(e.target.value)
        updateValue(false, 'required', {key: e.target.value, value: []})
    }

    const renderOptions = () => {
        const formGroups = currentForm.sub_sections;
        const options = []
        formGroups.forEach(p => {
            const inputs = p.inputs.filter(item => item.type === 'select');
            inputs.forEach(c => {
                options.push({
                    key: c.input_key,
                    text: p.header + " - " + c.label
                })
            })
        })

        return options.filter(item => item.text.indexOf('undefined') < 0).filter(item => item.text.indexOf('State') < 0)
    }

    const selectOptions = renderOptions()

    const findObject = () => {
        let returnVal = false
        const checkKey = selKey || selected.required?.key
        currentForm?.sub_sections?.forEach(ss => {
            const foundInput = ss.inputs?.find(input => input.input_key === checkKey);
            if (foundInput) {
                returnVal = foundInput
            }
        })

        return returnVal
    }

    const requiredTarget = findObject()

    const selectedOptions = (val) => {

        let newValue = selected.required || {
            key: selKey,
            value: []
        }

        // add or remove based on if its present
        if (newValue.value?.indexOf(val) > -1) {
            newValue.value = newValue.value?.filter(item => item !== val)
        } else {
            newValue.value.push(val)
        }

        updateValue(false, 'required', newValue) 

        setNewKey(false) 
        setSelKey(false)
    }

    const handleRevertToSimple = () => {
        updateValue(false, 'required', {key: true, value: []}) 
        setNewKey(false) 
        setSelKey(false)
    }

    return (
        <>
            <Typography variant="h5"  style={{float: "Left"}}>Required</Typography>
            <br /><br />
            {selected.type === 'checkBoxes' && selected.selection?.value?.length < 2 ?
                <Alert severity="error">
                    <b>BE ADVISED: CHECKBOXES WITH FEWER THAN 2 OPTIONS CANNOT BE REQUIRED ON FORM. Your settings here will not be lost but will not be used.</b>
                </Alert>
            : null}
            
            <br /><br />
            <FormControlLabel
                label="Required Field"
                control={
                    <Checkbox
                        checked={selected.required}
                        onChange={toggleChecked}
                    />
                }
            />
            <FormHelperText id="component-helper-text">
                Required indicates that the client MUST enter a value for this item to submit an entry.
            </FormHelperText>
            
            

            {selected.required || newKey ? 
            <>
            <br />
            <Typography variant="h6" >Advanced Options</Typography>
            <Typography variant="p" >
                The options below are OPTIONAL. These selections allow you to set this field as required ONLY WHEN a specific entry is selected on another form item. For example, if you want &quot;License Type&quot; to be required ONLY for those selecting &quot;Sampling Event&quot; as it would only be licensed clients that are able to have a sampling event at all. This sort of logic is what can be applied.
            </Typography>
                <FormControl fullWidth>
                    
                    <InputLabel id="demo-simple-select-label">Available Keys</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        style={{width: "100%"}}
                        id="demo-simple-select"
                        defaultValue={""}
                        value={selKey || (selected?.required?.key && selected?.required?.key !== '1' ? selected.required?.key : "")}
                        label="Validate Entry"
                        onChange={handleSelection}
                    >   
                        {selectOptions.map((option, i) => {
                            return <MenuItem key={option.key + i + 'selectOpt'} value={option.key}>{option.text}</MenuItem>
                        })}
                    </Select>
                    <FormHelperText id="component-helper-text">
                        Only &quot;select&quot; form items are available, such as License Type. Items that have a specific list of choices.
                    </FormHelperText>
                </FormControl>
                
                {requiredTarget ? 
                <TableContainer>
                <Alert severity="warning">BE AWARE: if you change the text or options of the item selected above it will not automatically update the choices below. If you change the text of these options then you must come back here and re-select the desired choices.</Alert>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            
                        <TableHead>
                            <TableRow>
                                <TableCell>Included</TableCell>
                                <TableCell>Unit</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                                
                        {requiredTarget?.selection?.value?.map((row, i) => {
                            const checked = selected.required?.value?.indexOf(row) > -1
                            return (
                                <TableRow
                                    key={selected.label + "-" + row+i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checked || false}
                                                    onChange={() => selectedOptions(row)}
                                                />
                                            }
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row}                 
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow >
                            <TableCell colSpan={2} align="center" >
                                <Button variant="contained" onClick={handleRevertToSimple}>Undo Complex Options, Revert to Simple Requirement</Button>
                            </TableCell>                            
                        </TableRow>

                        </TableBody>

                    </Table>
                </TableContainer>
                : null}
                </>
            : null }



        </>                              
    );

}

export default Required;

