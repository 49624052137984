import React from 'react';

// mui
import Card from '@mui/material/Card';
import FormLabel from '@mui/material/FormLabel';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

/** Non-interactive markup text
 * 
 * @param {*} param0 
 * @returns 
 */
const StaticMarkup = ({ value }) => {
    console.log(value.label)
    return (
        <Grid item xs={value.xs} md={value.md} >
            <FormLabel style={{textAlign: 'left', float: "left"}}>{value.label}</FormLabel>  
            <br />
            <br />
            <Card variant="outlined" style={{ backgroundColor: "transparent"}} >
                <CardContent style={{ paddingTop: '0px' }}>
                    <div style={{ textAlign: 'left', float: "left" }} dangerouslySetInnerHTML={{ __html: value.text }} />
                </CardContent>
            </Card>
        </Grid >
    );
}

export default StaticMarkup;
