import * as React from 'react';
import { cloneDeep } from 'lodash';

import validationChecks from '../../../utils/functions/validationChecks';
import iterateRequirements from '../../../utils/functions/iterateRequirements';

// components
import InputObjRender from '../../Form/InputObjRender'
import headerIcons from '../../Form/headerIcons';

// mui
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';

const theme = createTheme({
  typography: {
    allVariants: {
      color: 'black',
    },
  },
});


const LabUseEntry = ({ data, handleSaveEntries, userInput, setUserInput, mode, setMode }) => {

  // Clone form and get references to sub-sections as they may need to have items removed depending on the current mode.
  // Note: We may want to make this dynamic instead of hard-coding section IDs, as the form editor allows for an arbitrary
  //       number of sections with any names.
  const adminForm = cloneDeep(data?.forms?.sections?.find(f => f.type === 'admin-receive'));
  const adminReceive = adminForm.sub_sections.find(ss => ss.sectionKey === 'adminReceive');
  const adminAdditional = adminForm.sub_sections.find(ss => ss.sectionKey === 'adminAdditional');

  // filter out stuff here sometimes
  if (mode !== 'receive-admin') {
    adminReceive.inputs = adminReceive.inputs.filter(input => input.adminStandard);
    adminAdditional.inputs = adminAdditional.inputs.filter(input => input.adminStandard);
  }

  const [value, setValue] = React.useState({})
  const [validationErrors, setValidationErrors] = React.useState([]);
  const [checkRequirements, setCheckRequirements] = React.useState(false)

  /**
   * Checks if user input is valid according to configured rules.
   * @param {*} value Values to be validated.
   * @returns true is input is valid, otherwise false.
   */
  const validateInput = (value) => {
    let errors = [
      ...iterateRequirements(value, adminForm, -1),
      ...validationChecks(value, adminForm)
    ];

    // Update state with errors to be listed.
    setValidationErrors(errors);

    // More than 0 errors = invalid.
    return errors.length === 0;
  }

  const handleInput = (e, cat) => {
    const newValue = cloneDeep(value)
    if(e.target.value == null || e.target.value === '')
      delete newValue[cat];
    else
      newValue[cat] = e.target.value
    setValue(newValue)

    // Validate input and show errors if and only if user has already attempted submission.
    if (checkRequirements) {
      validateInput(newValue);
    }
  }

  const handleConfirm = () => {

    // Now that the user has attempted submission, start displaying errors.
    setCheckRequirements(true);

    // Only proceed if input is valid.
    if (validateInput(value)) {

      const newInput = cloneDeep(userInput)

      let newRec = cloneDeep(value)
      newRec.dateTime = new Date()
      newRec.type = mode.indexOf("relinquish") > -1 ? 'relinquish' : 'receive'
      newRec.staff = mode.indexOf("courier") > -1 ? 'courier' : 'admin'

      newInput.signatures.push(newRec)

      setCheckRequirements(false);
      setUserInput(newInput);
      setValue({});
      setMode("home");
      handleSaveEntries(newInput);
    }
  };

  const handleCancel = () => {
    setMode("home")
  }

  return (
    <>
      <ThemeProvider theme={theme}>

        <Grid container spacing={2} style={{ marginTop: "15px" }} >

          {adminReceive.inputs.length ?
            <>
              <Grid item xs={12} md={12} className="formHeaderContainer">{headerIcons.find(i => i.key === adminReceive.icon) ? headerIcons.find(i => i.key === adminReceive.icon).component : null}<Typography component='h5' variant='h5'>{adminReceive.header}</Typography></Grid>
              <InputObjRender
                checkRequirements={checkRequirements}
                sourceDataObj={adminReceive}
                objKey={"adminReceive"}
                data={data}
                userInput={value}
                disabled={false}
                handleInput={handleInput}
              />
            </>
            : null}

          {adminAdditional.inputs.length ?
            <Accordion style={{ marginTop: '15px', width: '100%', backgroundColor: '#eeeeee' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant='h5' style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  {headerIcons.find(i => i.key === adminAdditional.icon) ? headerIcons.find(i => i.key === adminAdditional.icon).component : null}{adminAdditional.header}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>

                <Grid container spacing={2}>
                  <InputObjRender
                    checkRequirements={checkRequirements}
                    sourceDataObj={adminAdditional}
                    objKey={"adminAdditional"}
                    data={data}
                    userInput={value}
                    disabled={false}
                    handleInput={handleInput}
                    adminAlt={true}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
            : null}

        </Grid>
      </ThemeProvider>

      {
        validationErrors.length > 0 &&
        <Alert style={{ textAlign: 'left', marginTop: '15px' }} severity="error">
          There are errors or missing information in the form(s) above (see below for more detail). Please resolve these before continuing.
          <ul style={{ textAlign: 'left' }}>
            {validationErrors.map((er, i) => {
              return <li key={er + '-' + i} >{er}</li>
            })}
          </ul>
        </Alert>
      }

      <DialogActions>
        <Button onClick={handleConfirm} disabled={checkRequirements && validationErrors?.length > 0}>Submit</Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </DialogActions>

    </>
  );
}

export default LabUseEntry;
