import React from 'react';

import {VisibilityOptions} from '../FormEditor/VisibleOnTable'

// components
import RelinquishmentSignatures from './RelinquishmentSignatures';
import ReceiptSignatures from './ReceiptSignatures';

// mui
import Grid from '@mui/material/Grid';

// utils

const SignaturesBody = ({ data, userInput, setUserData, setMode, setGlobalLoading, samplingMode }) => {

  /**
   * Gathers fields to display in signature section.
   * @param {string} formName ID of form to locate.
   * @returns Set of fields to show, in the order they appear when being filled out, omitting any that are configured to be hidden in form.
   */
  const findFields = (formName, visibilityKey) =>
    data.forms.sections
      .find(x => x.type === formName) // Locate form by ID
      ?.sub_sections?.map(x => x.inputs.sort((a, b) => a.order - b.order)).flat() // Sort inputs by order within each subsection
      .filter(x => x.visibility === visibilityKey || x.visibility === VisibilityOptions.UNCONFIGURED.key) // Keep only fields configured to be shown here (grandfather in any unconfigured fields)
      .map(x => ({ // Keep only needed info
        key: x.input_key,
        displayName: x.label
      })) ?? [];


  return (
    <Grid style={{ marginTop: "25px", width: "100%" }} container spacing={0}>
      <Grid xs={5}>
        <RelinquishmentSignatures userInput={userInput} setUserData={setUserData} samplingMode={samplingMode} />
      </Grid>

      <Grid xs={7} style={{ paddingLeft: '5px' }}>
        <ReceiptSignatures fields={findFields('admin-receive', VisibilityOptions.SIGNATURE_TABLE.key)} userInput={userInput} samplingMode={samplingMode} />
      </Grid>
    </Grid>

  );
}

export default SignaturesBody;
