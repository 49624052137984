import * as React from 'react';
// mui
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from "@mui/material/Divider";

// tools
import './materialSummary.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const secondsToDisplay = (seconds) => {
    const hours = seconds/60/60

    if (hours > 48) {
        const days = hours / 24
        return `${days} days`
    }

    return `${hours} hours`
}

const MaterialsSummary = ({ data, userInput, mode }) => {

  const fulfillmentForm = data?.forms?.sections?.find(s => s.type === 'materialOrder' && s?.materialKey === userInput.materialOrders[0]?.materialKey)
  const fulfillmentInputs = fulfillmentForm?.sub_sections?.map(ss => ss.inputs).flat()

  // const individualTesting = () => {
  //   const uniques = userInput.materialOrders[0].activeTests.filter(at => at.individual);
  //   const modeled = []

  //   uniques?.forEach(at => {
  //     const findGroup = modeled.find(g => g.individualName === at.individualName)
  //     if (findGroup) {
  //       findGroup.activeTests.push(at)
  //     } else {
  //       modeled.push({
  //         individualName: at.individualName,
  //         activeTests: [at]
  //       })
  //     }
  //   })

  //   return modeled;
  // }

  const parentList = userInput.materialOrders[0].activeTests?.filter(at => !at.individual).filter(at => at.consolidateTarget === 'parent')
  const PWSCheck = data.forms.sections.find(s => s.type === 'client')?.sub_sections.map(ss => ss.inputs)?.flat()?.find(input => input.input_key === 'PWSBottleOrder') || false;

  return (
    <>
      
      <div elevation={3} className="material-summary-body">
        <Grid container spacing={0}>
          {!userInput.status?.includes('fulfillment-complete') ? <Grid item lg={2} className="mat-sum-bookend" ></Grid> : null}
          <Grid item xs={12} md={6} lg={4} sx={{ borderTopLeftRadius: { xs: '0px', md: userInput.status?.includes('fulfillment-complete') ? '15px' : '0px'}}} className="mat-sum-body">
            <List>
                <ListItem>
                  <ListItemText
                    primary={<span><span style={{ fontWeight: 'bold'}}>Company Name </span><span style={{ float: 'right' }}>{userInput.companyName}</span></span>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<span><span style={{ fontWeight: 'bold'}}>Contact Name </span><span style={{ float: 'right' }}>{userInput.contactFirstName} {userInput.contactLastName}</span></span>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<span><span style={{ fontWeight: 'bold'}}>Email </span><span style={{ float: 'right' }}>{userInput.clientEmail}</span></span>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={<span><span style={{ fontWeight: 'bold'}}>Phone Number </span><span style={{ float: 'right' }}>{userInput.clientPhone}</span></span>}
                  />
                </ListItem>
            </List>
          </Grid>
          
          <Grid item xs={12} md={6} lg={4} className="mat-sum-body">
            <List>
              <ListItem>
                <ListItemText
                  primary={<span><span style={{ fontWeight: 'bold'}}>Order Details </span><span style={{ float: 'right' }}>{userInput.bottleRelinquishment}</span></span>}
                />
              </ListItem>
              {/* Only show this on relevant service lines */}
              {PWSCheck ? 
                <ListItem>
                  <ListItemText
                    primary={<span><span style={{ fontWeight: 'bold'}}>Public Water System </span><span style={{ float: 'right' }}>{userInput.PWSBottleOrder ? 'Yes' : 'No'}</span></span>}
                  />
                </ListItem>              
              : null}
              {userInput.additionalOrderComments ? 
                <ListItem>
                  <ListItemText
                    primary={<span><span style={{ fontWeight: 'bold'}}>Additional Comments </span><span style={{ float: 'right' }}>{userInput.additionalOrderComments}</span></span>}
                  />
                </ListItem>              
              : null}
            {userInput.shippingAddressLine1 ? 
              <ListItem>
                <ListItemText
                  primary={
                    <span>
                      <span style={{ fontWeight: 'bold'}}>Shipping Address </span>
                      <span style={{ float: 'right' }}>                          
                        {/* Address Line 1 */}
                        <span style={{ whiteSpace: 'noWrap', float: 'right' }}>{userInput.shippingAddressLine1}</span>
                        {/* Optional Address Line 2 */}
                        {userInput.materialAddressLine2 ? <><br /><span style={{ whiteSpace: 'noWrap', float: 'right' }}>{userInput.shippingAddressLine2}</span></> : null}
                        <br />
                        {/* Address Line 2 */}
                        <span style={{ whiteSpace: 'noWrap', float: 'right'}}>{userInput.shippingAddressCity}, {data.references?.states?.find(item => item.LIMS_ID === userInput.shippingAddressState)?.STATE_SHORTHAND} {userInput.shippingAddressZip}</span>
                      </span>
                    </span>
                  }
                />
              </ListItem>
            : null}

            {userInput.requestCooler?.[0] ? 
              <>
                <ListItem>
                  <ListItemText
                    primary={<span style={{ fontWeight: 'bold'}}>Additional Requests</span>}
                  />
                </ListItem>
                
                <ListItem>
                  <ListItemText
                    primary={<span><span style={{ float: 'left' }}><Checkbox disabled checked style={{ marginBottom: '4px', color: 'black' }} />{userInput.requestCooler[0]}</span></span>}
                  />
                </ListItem>
                </>
              : null}
              
            {userInput.bottleOrderAdditionalComments ?
              <ListItem>
                <ListItemText
                  primary={<span><span style={{ fontWeight: 'bold'}}>Additional Comments: </span><span style={{ float: 'right' }}>{userInput.bottleOrderAdditionalComments}</span></span>}
                />
              </ListItem>
            : null}
            </List>
          </Grid>
          
          {!userInput.status?.includes('fulfillment-complete') ? <Grid item lg={2} className="mat-sum-bookend-right" ></Grid> : null}
          {userInput.status?.includes('fulfillment-complete') ?
          
            <Grid item xs={12} md={12} lg={4} sx={{ backgroundColor: 'lightgrey', borderTopRightRadius: { xs: '0px', md: '15px'}, borderLeft: { xs: 'none', md: '2px solid grey'}}}>
                <List>
                  {userInput.materialOrders[0].trackingNumber ? 
                  <>
                    <ListItem>
                          <ListItemText
                            align='center'
                            primary={<b>UPS Tracking</b>}
                          />
                    </ListItem>
                    <ListItem>
                          <ListItemText                        
                            align='center'
                            primary={<span ><a style={{ color: 'blue' }} target='_blank' rel="noreferrer" href={`https://www.ups.com/track?loc=en_US&tracknum=${userInput.materialOrders[0].trackingNumber}`}>{userInput.materialOrders[0].trackingNumber}</a></span>}
                          />
                    </ListItem>    
                    <Divider style={{ width: "90%", margin: "auto", marginTop: "25px", marginBottom: "15px" }} />              
                  </>
                  : null}
                  
                  {fulfillmentInputs?.filter(i => i.input_key !== 'trackingNumber' && i.input_key !== 'shippingCost' && i.label)?.map(input => {
                    return (
                      <ListItem key={input.inputID} >
                        <ListItemText
                          primary={<span><span style={{ fontWeight: 'bold'}}>{input.label}: </span><span style={{ float: 'right' }}>{userInput.materialOrders[0][input.input_key] || 'NA'}</span></span>}
                        />
                      </ListItem>
                    )
                  })}                
              </List>
            </Grid>

          : null}

          {mode === 'review' && !userInput.status?.includes('fulfillment') && !userInput.status?.includes('submitted') ? 
            <Grid item xs={12} md={2} style={{margin: 'auto'}}>
            
            </Grid>          
          : null}
        </Grid>
      </div>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">

        <TableHead>
          <TableRow>
            <StyledTableCell className="column-header" align="center">Analysis</StyledTableCell>
            <StyledTableCell className="column-header" align="center">Count</StyledTableCell>
            <StyledTableCell className="column-header" align="center">Bottle Type</StyledTableCell>
            <StyledTableCell className="column-header" align="center">Sample Not To Exceed °C</StyledTableCell>
            <StyledTableCell className="column-header" align="center">Sample Expiration Time</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {parentList.map((t, j) => {
            const test = data.testing[t.type + 's']?.find(tt => tt.LIMS_ID === t.LIMS_ID);
            const fullTest = data.testing[t.type + 's']?.filter(tt => tt.LIMS_ID === t.LIMS_ID);

            const bottlesList = data.testing[t.type + 's']?.filter(tt => tt.LIMS_ID === test.LIMS_ID).map(tt => tt.BOTTLE_ID).filter(Boolean)
            const bottlesUnique = [...new Set(bottlesList)]
            
            const childTests = userInput.materialOrders[0]?.activeTests?.filter(at => !at.individual).filter(at => at.consolidateTarget === t.LIMS_ID)

            const completeGroupTests = data.testing[t.type + 's']?.filter(tt => tt.LIMS_ID === t.LIMS_ID || childTests.find(ct => ct.LIMS_ID === tt.LIMS_ID));
            const maxBottleHoldTemps = [...new Set(completeGroupTests.map(tt => tt.HOLDING_TEMPURATURE).filter(Boolean))].sort()

            const holdingTimes = completeGroupTests.map(ft => parseInt(ft?.MAXHOLD_SECONDS) || false).filter(f => f)

            const holdingTime = holdingTimes.length > 0 ? secondsToDisplay(holdingTimes.reduce((a, b) => Math.min(a, b))) : 'Not Specified'

            return (
              <TableRow
                key={"selected-"+t.LIMS_ID}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className="testing-row"
              >
                <TableCell className="summary-text" align="center">
                  <ul style={{listStyleType: 'none', padding: '0'}}>
                    <li>{test?.DISPLAYNAME}</li>
                    {childTests.map((ct, k) => {
                      const childTest = data.testing[ct.type + 's']?.find(tt => tt.LIMS_ID === ct.LIMS_ID);
                      return <li key={ct.LIMS_ID + k}>{childTest?.DISPLAYNAME}</li>
                    })}
                  </ul>
                </TableCell>
                <TableCell className="summary-text" align="center">
                  <ul style={{listStyleType: 'none', padding: '0'}}>
                    {
                      bottlesUnique.map(bot => {
                        const repBottles = fullTest.find(ft => ft.BOTTLE_ID === bot)?.BOTTLE_COUNT || 1;
                        return <li key={bot}>{t?.count * repBottles}{repBottles > 1 ? <><br /><span style={{fontSize: '0.5em'}}>This test requires {repBottles} bottles per sample.</span></> : null}</li>
                      })
                    }
                    {bottlesUnique.length < 1 ? 1 : null}
                  </ul>                    
                </TableCell>
                <TableCell className="summary-text" align="center" style={{ minWidth: '150px' }}>
                  <ul style={{listStyleType: 'none', padding: '0'}}>
                    {
                      bottlesUnique.map(bot => {
                        const bottle = data.references?.bottles?.find(b => b.BOTTLE_LIMS_ID === bot)
                        return <li key={bot}>{bottle.BOTTLE_NAME || 'Container type designated at fulfillment'}</li>
                      })
                    }
                    {bottlesUnique.length < 1 ? 'Container type designated at fulfillment' : null}
                  </ul>
                </TableCell>
                <TableCell className="summary-text" align="center">
                  {maxBottleHoldTemps[0] || 'Not Specified'}
                </TableCell>
                <TableCell className="summary-text" align="center">
                  {holdingTime}
                </TableCell>
              
              </TableRow>
            )
          })}

          {/* Not sure how to use this yet, if necessary */}
          {/* {individualTesting().map(t => {

            return (
              <TableRow
                key={"selected-"+t.LIMS_ID}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className="summary-text" align="center">{t.individualName}</TableCell>
                <TableCell className="summary-text" align="center">
                {t.activeTests.map((at, i) => {
                  const test = data.testing[at.type + 's']?.find(tt => tt.LIMS_ID === at.LIMS_ID);
                  return <Chip key={at.LIMS_ID + i} variant="outlined" label={test.DISPLAYNAME} />
                })}
                  
                </TableCell>
              </TableRow>
            )
          })} */}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

export default MaterialsSummary;
