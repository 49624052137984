// util
import individualValCheck from './individualValCheck';

const validationChecks = (userInput, formSection) =>

  formSection.sub_sections.map(ss => ss.inputs
    .filter(input => input.validation && (userInput[input.input_key] != null) && !individualValCheck(input.validation.type, userInput[input.input_key]))
    .map(input => `Submission for ${input.label} must be of type ${input.validation.label}`)
  ).flat();


export default validationChecks;
