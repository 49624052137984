import React from 'react';
import { cloneDeep } from 'lodash';
import validator from 'validator';
import { VisibilityOptions } from '../FormEditor/VisibleOnTable'

// components
import GenericTextSubmit from '../dialogues/GenericTextSubmit';
import headerIcons from '../Form/headerIcons';

// mui
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import CreateIcon from '@mui/icons-material/Create';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import ButtonGroup from '@mui/material/ButtonGroup';

const FormSelector = ({ data, setData, selected, setSelected, currentForm, setGroupSel }) => {
  
  const [screenView, setScreenView] = React.useState("md")
  const [editName, setEditName] = React.useState(false)
  const [subName, setSubName] = React.useState('')
  const [newItem, setNewItem] = React.useState(false)

  const handleMove = (key, val) => {
    const newForm = cloneDeep(currentForm)
    const refArr = newForm.sub_sections;
    const newData = cloneDeep(data)

    const currIndex = currentForm.sub_sections.find(ss => ss.sectionKey === key).order - 1;
    const newIndex = currIndex + val
    const targetItem = refArr[currIndex]
    refArr.splice(currIndex, 1);
    refArr.splice(newIndex, 0, targetItem);
  

    refArr.forEach((item, i) => {
      item.order = i + 1
    })

    newData.forms.sections = newData.forms.sections.map(f => {
      return f.type === currentForm.type ? newForm : f
    })

    setData(newData)
  }

  const initiateChangeName = (key, val) => {
    setSubName(val)
    setEditName(key)
  }

  const handleChangeName = (val) => {
    setSubName(val)
  }

  const cancelChangeName = () => {
    setSubName('')
    setEditName(false)
  }

  const commitNewName = (key) => {
    // update data
    const newData = cloneDeep(data)
    const newCurrentForm = newData.forms.sections.find(s => {
      if (currentForm.samplingEventKey) {
        return s.samplingEventKey === currentForm.samplingEventKey
      } else {
        return s.type === currentForm.type
      }
    })
    const editedSubSection = newCurrentForm.sub_sections.find(ss => ss.sectionKey === key);
    editedSubSection.header = subName
    setData(newData)

    // reset
    setSubName('')
    setEditName(false)
  }

  const handleNewKey = () => {
    setNewItem({
        header: 'Set Key for New Form Item',
        body: 'A discreet key is required for form items and is how the value is mapped into LIMS.',
        helperText: <>This key must be in a specific format called &quot;camel case&quot;, e.g., camelCase, where a multi-word phrase is concatonated and each word AFTER the first word is capitolized and the entire string&apos;s first character is always lowercase. Below are more examples:
        <ul>
          <li>Client Name → clientName</li>
          <li>Client Name From Some Time In The Past → clientNameFromSomeTimeInThePast</li>
          <li>Something Simple → somethingSimple</li>
          <li>A Very Long String → aVeryLongString</li>
        </ul>
        When saving below the application will check for duplicate keys and alert you there. If there are any other issues please contact the application administrator for more assistance.</>
    })
  }

  const handleAddNewSubSection = (value) => {        

      if (value.indexOf(' ') > -1) {
          return 'Spaces are not allowed in key name.'
      }

      if (!validator.isAlphanumeric(value)) {
          return 'No special characters: letters and numbers only.'
      }
      const clientForm = data.forms?.sections?.find(f => f.type === 'client')
      const sampleForm = data.forms?.sections?.find(f => f.type === 'sample')
      const concated = [...clientForm.sub_sections, ...sampleForm.sub_sections]
      const allKeys = concated.map(ss => ss.sectionKey)

      if (allKeys.indexOf(value) > -1) {
          return 'Duplicate Key: please use a different name for this entry.'
      }

      const newData = cloneDeep(data)
      const newCurrentForm = newData.forms.sections.find(s => {
        if (currentForm.samplingEventKey) {
          return s.samplingEventKey === currentForm.samplingEventKey
        } else {
          return s.type === currentForm.type
        }
      })
      newCurrentForm.sub_sections.push({
        header: "New Group",
        icon: "AccountCircleIcon",
        order: currentForm.sub_sections.length + 1,
        inputs: [],
        sectionKey: value,
        labCode: "cfl",
        useAsAbove: false,
        requirements: [],
      })
      
      setSelected("")
      setData(newData)

      return true
  }

  const handleNewItem = (key) => {
    const newData = cloneDeep(data)
    const newCurrentForm = newData.forms.sections.find(s => {
      if (currentForm.samplingEventKey) {
        return s.samplingEventKey === currentForm.samplingEventKey
      } else {
        return s.type === currentForm.type
      }
    })
    const editedSubSection = newCurrentForm.sub_sections.find(ss => ss.sectionKey === key);
    editedSubSection.inputs.push({
      type: "input",
      order: editedSubSection.inputs.length + 1,
      label: "New Item",
      xs: 12,
      md: 12,
      input_key: "newInput",
      labCode: "cfl",
      required: {
          key: true,
          value: []
      },
      visibility: VisibilityOptions.UNCONFIGURED.key
    })

    setSelected({
      type: "input",
      order: editedSubSection.inputs.length,
      label: "New Item",
      xs: 12,
      md: 12,
      input_key: "newInput",
      labCode: "cfl",
      required: {
          key: true,
          value: []
      },
      visibility: VisibilityOptions.UNCONFIGURED.key
    })

    setData(newData)

  }

  return (
      <div style={{padding: "15px"}}>
        <GenericTextSubmit open={newItem} setOpen={setNewItem} submitEntry={handleAddNewSubSection} />

      <Typography variant="h5">Form Entries</Typography>
      <Typography variant="p">Click on an entry below to begin editing any field.</Typography>
      
      <div  style={{width: "90%", margin: "auto", marginTop: "15px"}}>
        <Button variant="contained" onClick={handleNewKey} style={{width: "100%"}} >Add New Group (will populate at the bottom of this page)</Button>
      </div>

      <div style={{width: "90%", margin: "auto", marginTop: "15px"}}>
        <Typography variant="h6" style={{float: "left", marginRight: "15px"}}>Device View Preview</Typography>
        <ButtonGroup size="small" aria-label="small button group"> 
            <Button onClick={() => setScreenView("md")} variant={screenView === "md" ? "contained" : "outlined"}>Medium Screen View (md)</Button>
            <Button onClick={() => setScreenView("xs")} variant={screenView === "xs" ? "contained" : "outlined"}>Mobile View (xs)</Button>
        </ButtonGroup>
      </div>
      
      {currentForm?.sub_sections?.map((sub_section, j) => {

              const useIcon = headerIcons.find(icon => icon.key === sub_section.icon)
              return (
                  <Grid key={'Form-Selector-'+sub_section.sectionKey+"-"+j} container style={{width: "90%", margin: "auto", marginTop: "15px"}}>                        
                      <Grid item xs={12}>
                          
                          
                          {editName === sub_section.sectionKey ? 
                            <FormControl variant="standard" style={{width: "100%"}}>
    
                              <Input 
                                  value={subName}
                                  onChange={(e) => {
                                    handleChangeName(e.target.value);
                                  }}
                              />
          
                              <FormHelperText id="component-helper-text">
                                  Desired Header Name
                              </FormHelperText>
          
                          </FormControl>
                          :
                            <Typography variant="h6" style={{float: "left", width: "100%"}}>{useIcon ? useIcon.component : null}{sub_section.header}</Typography>
                          }

                          {editName === sub_section.sectionKey ? 
                            <>
                            <Tooltip title="Save Name">
                              <span>
                                <IconButton edge="end" aria-label="delete" onClick={() => commitNewName(sub_section.sectionKey)}>          
                                  <SaveIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip title="Cancel">
                              <IconButton edge="end" aria-label="delete" onClick={cancelChangeName}>          
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                            </>
                          :
                            <Tooltip title="Edit Group Name">
                              <span>
                                <IconButton disabled={editName} edge="end" aria-label="delete" onClick={() => initiateChangeName(sub_section.sectionKey, sub_section.header)}>          
                                  <CreateIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          }
                          
                          
                          
                          <Tooltip title="Move Group Up">
                            <IconButton disabled={editName || sub_section.order <= 1} edge="end" aria-label="delete" onClick={() => handleMove(sub_section.sectionKey, -1)} >          
                              <ArrowUpwardIcon />
                            </IconButton>
                          </Tooltip>
                          
                          <Tooltip title="Move Group Down">
                            <IconButton disabled={editName || sub_section.order >= currentForm.sub_sections?.length} edge="end" aria-label="delete" onClick={() => handleMove(sub_section.sectionKey, 1)} >          
                              <ArrowDownwardIcon />
                            </IconButton>                              
                          </Tooltip>

                          <Tooltip title="Add New Item to Group">
                            <IconButton disabled={editName} edge="end" aria-label="delete" onClick={() => handleNewItem(sub_section.sectionKey)}>          
                              <AddIcon />
                            </IconButton>  
                          </Tooltip>   

                          
                      <Grid item sx={12}><Button disabled={!!selected} onClick={() => setGroupSel(sub_section.sectionKey)}>View Additional Tools</Button></Grid>
                      </Grid>
                      

                      {sub_section.inputs?.map((input, k) => {
                          // Render Each Form Entry
                          return (
                            <Grid
                              item
                              md={input[screenView]}
                              key={"formItem" + input.input_key + "-" + sub_section.sectionKey + "-" + k}
                              style={{
                                outline: "0.5px solid black",
                                padding: "0px",
                              }}
                            >
                              <button
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  minHeight: "50px",
                                  backgroundColor: selected.input_key === input.input_key ? "#00FFAA" : "#EEEEEE"
                                }}
                                onClick={() => {
                                  setSelected(input)
                                  setGroupSel(false)
                                }}
                              >
                                {input.label || `No Header Name, input_key: ${input.input_key}`}
                              </button>
                            </Grid>
                          );

                          })}

                  </Grid>
              )
          })
      }  
      
      </div>
  );

}

export default FormSelector;
