/* eslint-disable no-useless-escape */
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

// components
import COCHeader from '../COCRender/COCHeader';
import COCBody from '../COCRender/COCBody';
import COCFooter from '../COCRender/COCFooter';

// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ClearIcon from '@mui/icons-material/Clear';

// utils
import "../../utils/styles/COCPrint.css"

const Item = styled(Paper)(({ theme }) => ({    
  padding: theme.spacing(0),
  textAlign: 'center',
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// for future ref:
// https://github.com/diegomura/react-pdf
// regarding downloading instead of printing pdf

const COCPrint = ({ open, setOpen, userInput, maxPages, data, samplingMode }) => {
  
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    
  });

  const handleConfirm = () => {    
    setOpen(false)    
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const pages = []
  {for (let index = 0; index < maxPages; index++) {
    const renderSamples = userInput.samples.slice(0 + (index * 10), 10 + (index * 10))

    pages.push(
        
        <React.Fragment 
            key={"COC-Rendered-"+index}                       
        > 
          <Stack className="section-to-print">

              <Item elevation={0}><COCHeader  userInput={userInput} data={data}/></Item>
                      
              <Item elevation={0}>
                <COCBody                     
                  userInput={userInput} 
                  renderSamples={renderSamples} 
                  data={data} 
                  page={index}
                  samplingMode={samplingMode}
                  showBottles={samplingMode && userInput.status === 'sampling-complete'}
                />
              </Item>

              <Item elevation={0}><COCFooter  maxPages={maxPages} page={index} userInput={userInput} /></Item>

          </Stack>
          {index === maxPages - 1 ? null : <div className="page-break" />}    
        </React.Fragment>
        
        
                                  
           
    )
    
  }}

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleCancel}
        TransitionComponent={Transition}
        
      >
        
        <DialogContent style={{backgroundColor: "lightgrey"}}>
          

        <div  ref={componentRef} className="print-container" style={{ margin: "0", padding: "0" }}>
          {pages}
          <style type="text/css" media="print">{"\
            @page {\ size: landscape;\ }\
          "}</style>
        </div>

        </DialogContent>
        <DialogActions style={{backgroundColor: "lightgrey"}}>
          <Button  color="success" endIcon={<LocalPrintshopIcon />} onClick={handlePrint}>Print</Button> 
          <Button endIcon={<ClearIcon />} onClick={handleCancel}>Go Back</Button>         
        </DialogActions>
      </Dialog>
    </>
  );
}

export default COCPrint;
