import React from 'react';

// mui
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import { Button, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

// hopefully I look at this later
// on the ECOC rendered, look at the keys in Client and the relative sample, not other samples, just own sample keys. That's it! Don't go looking through the entire project.
// for TESTING, sends full object so make sure the rendering side is checking for that

const Dependency = ({ data, selected, updateValue, currentForm }) => {
    
    const [newKey, setNewKey] = React.useState(false)
    const [selKey, setSelKey] = React.useState(false)
    
    const handleSelection = (e) => {
        if (e.target.value !== selected.dependency?.key) {
            updateValue(false, 'dependency', {
                key: e.target.value,
                value: []
            }) 
        }
        setSelKey(e.target.value)
    }

    const renderOptions = () => {
        // needs to be full form, client and sample

        const clientForm = data.forms?.sections?.find(f => f.type === 'client') || false
        const sampleForm = data.forms?.sections?.find(f => f.type === 'sample') || false
        // only add adminForm if the current section is admin-form, we cannot have dependencies from this form to the main client form as this form is interacted with AFTER a complete client form is filled
        const adminForm = data.forms?.sections?.find(f => f.type === 'admin-receive' && currentForm.type === 'admin-receive') || false

        let options = []
        const concated = [...clientForm.sub_sections, ...sampleForm.sub_sections, ...adminForm?.sub_sections || []]
        concated.forEach(ss => {
            const selects = ss.inputs.filter(input => input.type === 'select' || input.type === 'checkBoxes')
            selects.forEach(input => {
                options.push({
                    key: input.input_key,
                    text: ss.header + " - " + (input.label || input.input_key)
                })
            })
        })

        options = options.concat({key: "testing", text: "Testing"})

        return options.filter(item => item.text.indexOf('undefined') < 0)
    }
    
    const selectOptions = renderOptions()

    const findObject = () => {
        let returnVal = false
        const checkKey = selKey || selected.dependency?.key
        const clientForm = data.forms?.sections?.find(f => f.type === 'client')
        const sampleForm = data.forms?.sections?.find(f => f.type === 'sample')
        const adminForm = data.forms?.sections?.find(f => f.type === 'admin-receive' && currentForm.type === 'admin-receive') || false
        
        const concated = [...clientForm.sub_sections, ...sampleForm.sub_sections, ...adminForm?.sub_sections || []]

        concated?.forEach(ss => {
            const foundInput = ss.inputs?.find(input => input.input_key === checkKey);
            if (foundInput) {
                returnVal = foundInput
            }
        })

        return returnVal
    }
    const requiredTarget = findObject()

    const selectedOptions = (val) => {
    
        let newValue = selected.dependency?.value || []

        if (newValue && newValue.indexOf(val) > -1) {
            newValue = newValue.filter(item => {
                return item !== val
            })
        } else {
            newValue.push(val)
        }

        updateValue(false, 'dependency', {
            key: selKey || selected.dependency.key,
            value: newValue
        }) 

        setNewKey(false) 
        setSelKey(false)
    }

    const handleRevertToSimple = () => {
        updateValue(false, 'dependency', false, 'delete')
        setNewKey(false) 
        setSelKey(false)
    }

    const testingForm = data.forms?.sections?.find(f => f.type === 'testing')
    return (
        <>
        <Typography variant="h5"  style={{float: "Left"}}>Dependency</Typography>
        <br /><br />
        <Typography variant="p" >
            A Dependency is a property that allows this form item to be HIDDEN unless a specific choice is made elsewhere. For example, if a user selects &quot;per serving&quot; as a reporting unit then the item for &quot;serving size&quot; would render, otherwise that option should be hidden to prevent confusion. At this time you may only have ONE category for a dependency. You may also set specific tests which are assigned to the service line.
        </Typography>

        
            {selected.dependency || newKey ? 
            <>
            <Button style={{width: "100%", marginBottom: "15px", marginTop: "15px"}} variant="outlined" onClick={handleRevertToSimple}>Revert to NO DEPENDENCY</Button>
            <br />
                <FormControl fullWidth>
                    
                    <InputLabel id="demo-simple-select-label">Available Keys</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        style={{width: "100%"}}
                        id="demo-simple-select"
                        defaultValue={""}
                        value={selKey || (selected?.dependency?.key && selected?.dependency?.key !== '1' ? selected?.dependency?.key : "")}
                        label="Validate Entry"
                        onChange={handleSelection}
                    >   
                        {selectOptions.map((option, i) => {
                            return <MenuItem key={option.key + i + 'selectOpt'} value={option.key}>{option.text}</MenuItem>
                        })}
                    </Select>
                    <FormHelperText id="component-helper-text">
                        Only &quot;select&quot; form items are available, such as License Type. Items that have a specific list of choices.
                    </FormHelperText>
                </FormControl>
                
                {selected.dependency?.key !== "testing" && requiredTarget ? 
                // This works on DB or Specified Lists
                <>
                    <TableContainer sx={{ minWidth: 650, maxHeight: "800px", overflowY: "scroll" }}  >
                        <Table stickyHeader size="small" aria-label="a dense table">
                                
                            <TableHead>
                                <TableRow>
                                    <TableCell>Included</TableCell>
                                    <TableCell>Selectable Item</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                    
                                {requiredTarget?.selection?.value?.map((row, i) => {
                                    // get ref item from db if its a db thing
                                    const displayItem = requiredTarget.selection.source === "specified" ? row : data.references[requiredTarget.selection.source]?.find(item => item.LIMS_ID === row)?.DISPLAYNAME
                                    const checked = selected.dependency?.value?.indexOf(row) > -1
                                    return (
                                        <TableRow
                                            key={selected.label + "-" + row+i}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checked || false}
                                                            onChange={() => selectedOptions(row)}
                                                        />
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {displayItem || row}                 
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}

                            </TableBody>

                        </Table>
                    </TableContainer>
                    
                    <Alert severity="warning">BE AWARE: if you make NO SELECTION above it will treat a lack of a selection as the dependency. Thus, if the item viewed is left empty it will show this item.</Alert>
                    <Alert severity="error">BE AWARE: if you change the text or options of the item selected above it will not automatically update the choices below. If you change the text of these options then you must come back here and re-select the desired choices.</Alert>
                    </>
                : 
                    null
                }

                    {selected.dependency?.key === "testing" ? 
                        <>
                    
                            
                            <TableContainer style={{maxHeight: "500px"}}  >
                            <Alert severity="warning">Something Important can be stated here. There is nothing important to say. Keep up the great work!</Alert>

                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Included</TableCell>
                                            <TableCell>Group</TableCell>
                                            <TableCell>Selectable Item</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                            
                                    {testingForm.testing_groups?.map((row, i) => {
                                        // get testing to properly display and send in
                                        return (
                                            <React.Fragment key={"tg"+i}>
                                            {row.tests.map((t, j) => {
                                                const checked = !!selected.dependency?.value?.find(item => item.LIMS_ID === t.LIMS_ID)
                                                const test = data.testingNew[t.type + 's'].find(item => item.LIMS_ID === t.LIMS_ID)
                                        
                                                return (
                                                    <TableRow
                                                        key={t.LIMS_ID + "-" + i + "-" + j}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={checked || false}
                                                                        onChange={() => selectedOptions(t)}
                                                                    />
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.group}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {test?.PRODUCTCODE} - {test?.DISPLAYNAME}                 
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })

                                            }
                                            </React.Fragment>
                                        )
                                    })}


                                    <TableRow >
                                        <TableCell colSpan={2} align="center" >
                                        </TableCell>                            
                                    </TableRow>

                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </>
                    :   
                        null
                    }

                </>
            : 
                <Button style={{width: "100%", marginBottom: "15px", marginTop: "15px"}} variant="outlined" onClick={() => setNewKey(true)}>Use Dependency</Button> 
            }



        </>                              
    );

}

export default Dependency;

