import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { VisibilityOptions } from '../FormEditor/VisibleOnTable'

// mui
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';

// utils
import FormAPI from '../../utils/API/api-form';

const RelinquishmentTools = ({ userData, setUserData, data }) => {

  const [loading, setLoading] = React.useState(false)
  const [error, seterror] = React.useState(false)

  // Gather extra fields to be shown only to admins
  const adminOnlyDataGroups =
    data.forms.sections.map(section => {

      // Get appropriate info for section

      let sectionHeader;
      let sectionData;

      switch (section.type) {
        case 'admin-receive':
          // For admin receipts, only use data from last signaturre
          sectionHeader = 'Admin Receipt Signature';
          sectionData = userData?.signatures?.filter(s => s.type === 'receive' && s.staff === 'admin')?.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))[0];
          break;
        default:
          sectionHeader = section.type;
          sectionData = userData;
          break;
      }

      return {
        // Form
        key: section.type,
        header: sectionHeader,
        items: section.sub_sections?.map(x => ({
          // Form sub-section
          key: x.sectionKey,
          header: x.header,
          items: x.inputs.sort((a, b) => a.order - b.order)
            .filter(x => x.visibility === VisibilityOptions.ADMIN_AREA.key)
            .map(x => ({
              // Field
              key: x.input_key,
              header: x.label,
              value: sectionData?.[x.input_key]
            }))
            .filter(x => x.value != null) // Omit fields with no associated value
        }))
          .filter(x => x.items.length) // Omit sub-sections with no fields
      };
    })
      .filter(x => x.items.length) // Omit sections with no sub-sections
    ?? [];



  const handleChange = async (key) => {
    setLoading(true)
    seterror(false)
    const newUserData = cloneDeep(userData)
    newUserData[key] = !newUserData[key]
    if (key === 'imported' && newUserData[key]) {
      newUserData.archive = true
    }
    try {
      const res = await FormAPI.saveClientSubmission({ data: { userJSON: newUserData } })
      setUserData(res.data)
      setLoading(false)
    } catch (err) {
      // do error
      console.log(err)
      seterror('Something went wrong setting and saving submission. Please try refreshing the page, trying again, and if the issue persists contact the development team.')
    }


  }

  return (
    <div style={{ backgroundColor: 'lightGrey', padding: '10px', borderRadius: '5px', marginBottom: '15px' }}>


      <Typography align="left" variant="h5" >Submission Tools</Typography>
      <Divider variant="middle" />

      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <Typography align="left" variant="h6" >Submission Status Togglers</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox disabled={loading} checked={userData.imported} onChange={() => handleChange('imported')} name="Imported" />
            }
            label="Submission Imported"
          />
        </FormGroup>
        <FormHelperText>Marking submission as imported communicates that this submission&lsquo;s data has been importoed to LIMS. Warning, this is not an automated feature. This also prevents further commenting on submission.</FormHelperText>
      </FormControl>

      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox disabled={loading} checked={userData.archive} onChange={() => handleChange('archive')} name="Imported" />
            }
            label="Submission Archvied"
          />
        </FormGroup>
        <FormHelperText>Marking submission as archived hides it from the main Admin table. This also prevents further commenting on submission.</FormHelperText>
      </FormControl>

      {
        // Show admin-only data
        (adminOnlyDataGroups.length > 0) &&
        <>
          <Typography align="left" variant="h5" >Extra Data</Typography>
          <Divider variant="middle" />
          <br />
          {
            adminOnlyDataGroups.map(section =>
              <div key='' style={{ backgroundColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}>
                <Typography style={{ fontWeight: 'bold' }} align='Left' variant="h6" >{section.header}</Typography>
                {
                  section.items.map(subsection =>
                    <div key='' style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
                      <Typography style={{ fontWeight: 'bold' }} align='Left' variant="h6" >{subsection.header}</Typography>
                      {
                        subsection.items.map(field =>
                          <Typography align='Left' key='' variant="body1" >{field.header}: {field.value}</Typography>
                        )
                      }
                    </div>)
                }
              </div>
            )
          }
        </>
      }

      <Typography align="left" variant="p" >{error}</Typography>
      {loading ? <LinearProgress /> : false}
    </div >
  )

}

export default RelinquishmentTools;
