import React from 'react';

// mui
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';

const UserCheckBoxes = ({ value, refItem, disabled, handleInput, checkRequirements }) => {

  // is this not using sampleIndex right now? Going to need to fix that eventually

    // default value on this is an empty array since checkboxes can potentailly be multi-select
    // will need to dig into this more
    let useInput = refItem && refItem[value.input_key] ? refItem[value.input_key] : []

    const canMirror = value.mirrorKey ? true : false    
    const isMirrored = disabled ? disabled : false
    if (canMirror && isMirrored) {
        useInput = refItem[value.mirrorKey] ? refItem[value.mirrorKey] : []
    }

    // DO NOT MAKE REQUIRED if the selection is single point, so ignore all rquirements if they happen to be set
    const renderError = checkRequirements && value.required && useInput.length < 1 && value.selection.value.length > 1

    const handleSelect = (e) => {
      // deal with an array      

      let sendArr = []

      // check if input exists yet
      if (refItem[value.input_key]) {
        // does exist, set the temp item to it
        sendArr = [...refItem[value.input_key]]
        // get index of selected item, this is referenced if we are REMOVING or ADDING
        const index = sendArr.indexOf(e.target.value) 


        // check if its a single or multiple selector here
        if (value.selectType === "single") {
          // single type, completely kill array if not present, kill it otherwise

          if (index > -1) {
            // kill contents if it exists
            sendArr = []
            
          } else {
            // otherwise fully replace arr
            sendArr = [e.target.value]
          } 


        } else {
          // array type, add or slice depending on index
          if (index > -1) {
            sendArr.splice(index, 1)
          } else {
            sendArr.push(e.target.value)
          } 
        }



      } else {
        // doesn't exist, create and push it
        sendArr = [e.target.value] 



      }
      
      handleInput(sendArr, value.input_key)

    }

    const columns = 12 / value.columns

    const renderOptions = value.selection?.value.map((o, i) => {
      
      return (
        <Grid item md={columns} key={`cbGrid-${o}-${i}`}>          
          <FormGroup key={`cb-formgroup-${o}-${i}`}>
            <FormControlLabel key={`cb-fblabel-${o}-${i}`} style={{textAlign: 'left'}}
              control={
                <Checkbox key={`cb-${o}-${i}`} value={o} checked={refItem[value.input_key]?.indexOf(o) > -1} onChange={handleSelect} name={o} />
              }
              label={o}
              disabled={disabled}
            />
          </FormGroup>
        </Grid>
      )
    })

    return (
      <Grid item xs={value.xs} md={value.md} id={`cb-maingrid-${value.input_key}`}>
        <FormControl error={renderError} sx={{width: "100%"}} id={`cb-mainformcontrol-${value.input_key}`}>    
        <FormLabel id={`cb-mainlabel-${value.input_key}`} style={{textAlign: 'left'}}>{value.label}</FormLabel>  
            <Grid container id={`cb-maingridcontainer-${value.input_key}`}>

              {renderOptions}

            </Grid>
            {renderError ? <FormHelperText id={`cb-helpertext-${value.input_key}`}>Required Entry</FormHelperText> : null}
        </FormControl>
      </Grid>
    );
}

export default UserCheckBoxes;
