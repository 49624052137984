import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import validator from 'validator';

import validationChecks from '../../../utils/functions/validationChecks';
import iterateRequirements from '../../../utils/functions/iterateRequirements';

// components
import InputObjRender from '../../Form/InputObjRender.js';
import BottlesForm from '../../Form/testing/BottlesForm.js';
import Signature from '../../dialogues/Signature.js';

// mui
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import LinearProgress from '@mui/material/LinearProgress';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Alert from '@mui/material/Alert';

// utils
import headerIcons from '../../Form/headerIcons.js';
import FormAPI from '../../../utils/API/api-form.js';

const GeneralSamplingEventWorksheets = ({ userData, setUserData, data }) => {

  const [changeDetected, setChangeDetected] = React.useState(false)
  const [entryErrors, setEntryErrors] = React.useState([])
  const [submissionError, setSubmissionError] = React.useState(false)
  const [checkRequirements, setCheckRequirements] = React.useState(false)
  const [loadingLocal, setLoadingLocal] = React.useState(false)
  const [signOpen, setSignOpen] = React.useState(false)

  const samplingForm = data?.forms?.sections?.filter(s => s.triggerInputs?.length > 0).find(s => s.samplingEventName === userData.submissionType.split(',')?.[1] || s.samplingEventKey === userData.submissionType.split(',')?.[1])

  const handleGeneralSamplingInput = (e, cat) => {
    const newUserData = cloneDeep(userData)

    if (cat === 'tempurature' && !validator.isNumeric((e.target.value * 10).toString())) {
      return
    }
    newUserData.samplingPerson = { ...newUserData.samplingPerson || {}, [cat]: e.target.value }
    setUserData(newUserData)
    setChangeDetected(true)
  }

  const handleInput = (e, key, alt, sample) => {
    // Determine which field holds needed value. 
    //   Typically, value is in target of UI event (e) which is passed directly up.
    //   Sometimes value is passed to alt, which will be false/null otherwise.
    //   For items with multiple values, array of values is passed to e instead of UI event.
    const useValue = alt || (Array.isArray(e) ? e : e?.target?.value);
    const deleteMode = useValue == null || useValue === '';

    // need to jam these into the "sampling Event Data" object so it shows up on coc
    const newUserData = cloneDeep(userData)
    if (!newUserData.samplingEventInputs) {
      newUserData.samplingEventInputs = []
    }

    const foundInput = newUserData.samplingEventInputs.find(sei => sei.id === sample)
    if (foundInput) {
      if (deleteMode) {
        // Remove empty entry.
        delete foundInput[key];
        // Remove sampling event input if there are no entries left.
        const props = Object.entries(foundInput);
        if(props.length == 1 && props[0][0] == 'id'){
          newUserData.samplingEventInputs = newUserData.samplingEventInputs.filter(x => x.id != sample);
        }
      } else {
        foundInput[key] = useValue;
      }
    } else if(!deleteMode) {
      newUserData.samplingEventInputs = [...newUserData.samplingEventInputs, {
        id: sample,
        [key]: useValue
      }]
    }

    setUserData(newUserData)
    setChangeDetected(true)
  }

  const saveData = async (submissionData, status) => {
    setLoadingLocal(true)

    // api to save data here
    const sendData = submissionData || cloneDeep(userData);
    sendData.status = status || 'sampling-saved';
    try {
      const res = await FormAPI.saveClientSubmission({
        data: {
          userJSON: sendData
        }
      })

      setUserData(res.data)
      setChangeDetected(false)

      if (res.status === 200 && status === 'sampling-complete') {
        // ship email only on complete
        await FormAPI.submitClientDataEmail({ data: res.data, type: 'samplingEvent' })
      }
    } catch (err) {
      console.log(err)
      setSubmissionError(true);
    }

    setLoadingLocal(false)

  }

  const completesampling = (status) => {
    setSubmissionError(false);

    const submissionData = cloneDeep(userData)
    submissionData.samplingPerson.date = new Date();

    // Ensure there is a sampling event object to validate for every sample
    const validationItems = cloneDeep(submissionData.samplingEventInputs) ?? [];
    submissionData.samples.forEach(sample => {

      let validationItem = validationItems.find(x => x.id === sample.id);
      if (!validationItem) {
        validationItem = { id: sample.id }
        validationItems.push(validationItem);
      }
      validationItem.activeBottles = cloneDeep(sample.activeBottles);
    });
    validationItems.sort((a, b) => a.id - b.id);

    // Validate before submitting
    let errors = [];
    if (!submissionData.samplingPerson.name) {
      errors.push('Sampling person name required');
    }

    validationItems.forEach(x => {

      const sampleName = `Sample ${(x.id + 1).toString()}`;
      const addSampleError = e => errors.push(`${sampleName}: ${e}`);

      iterateRequirements(x, samplingForm, -1).forEach(addSampleError);
      validationChecks(x, samplingForm).forEach(addSampleError);
      if (data.forms?.bottlesMenu) {
        x.activeBottles.forEach(b => {
          if (!b.lotNumber || b.lotNumber?.length < 1) {
            addSampleError(`Bottle ${b.BOTTLE_NAME} must have Lot Number!`);
          }
        });
      }

    });

    setEntryErrors(errors);
    setCheckRequirements(errors.length > 0);

    if (!(errors.length && status === 'sampling-complete')) {
      // submit here through API
      submissionData.signatures = [...submissionData.signatures, { name: submissionData.samplingPerson.name, dateTime: submissionData.samplingPerson.date, staff: 'sampler', type: 'receive', tempurature: submissionData.samplingPerson.tempurature, cooling: 'NA' }]
      saveData(submissionData, status)
    }
  }

  const samplingComplete = userData.signatures?.find(s => s.staff === 'sampler' && s.type === 'receive')

  return (
    <>
      <Signature data={data} samplingMode={false} open={signOpen} setOpen={setSignOpen} userInput={userData} setUserData={setUserData} />

      <Paper elevation={3} style={{ padding: '15px', backgroundColor: 'rgba(240,240,240,0.8)' }}>

        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            {userData.samplingData?.samplingStartedName && userData.status.includes('sampling') ? 
              <Typography>Sampling Started by {userData.samplingData?.samplingStartedName} on {userData.samplingData?.samplingDateTime}</Typography>
            : 
              <>
                <TextField   
                  style={{ width: '50%' }}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  label={<span>Name<span style={{color: "red"}}>*</span></span>}
                  error={entryError && !userData.samplingData?.samplingStartedName ? true : false}
                  size="small"
                  value={userData.samplingData?.samplingStartedName || ''}
                  onChange={(e) => handleInput(e, 'samplingStartedName')}
              />
              <Button disabled={!userData.samplingData?.samplingStartedName} style={{ width: '45%', height: '40px' }} variant="contained" color="warning" onClick={() => completesampling('sampling-started')} >Begin sampling</Button>
              </>
            }
          </Grid> */}

          <Grid item xs={12} md={12} className="formHeaderContainer" justifyContent='center'><Typography component='h5' variant='h5'>Sampling Event Fulfillment Form</Typography></Grid>

          {userData.status === 'saved' ?
            <>
              <Typography component='h5' variant='h5' style={{ fontWeight: 'bold', margin: 'auto' }}>Submission must have initial relinquishment sign off prior to sampling.</Typography>
              <Button onClick={() => setSignOpen(true)} variant='contained' endIcon={<BorderColorIcon style={{ fontSize: '2rem' }} />} style={{ margin: 'auto', backgroundColor: 'rgba(38, 168, 137, 1)', width: '95%', height: '75px', fontSize: '1.5rem', fontWeight: 'bold' }} color="success">
                Click Here to Sign Off on Submission
              </Button>
            </>
            : null}
          {userData.samples.map((sample, i) => {
            const sampleInput = (e, key, alt) => {
              handleInput(e, key, alt, sample.id)
            }
            return (
              <Grid item xs={12} md={12} key={'fulfillment-' + i} style={{ borderBottom: i < userData.samples.length - 1 ? '3px grey solid' : 'none', paddingBottom: '20px', margin: '5px' }} >

                <Typography component='h5' variant='h5'>Sample {i + 1}</Typography>

                {samplingForm?.sub_sections.map(ss => {

                  const useIcon = headerIcons.find(ic => ic.key === ss.icon)
                  const useInputs = cloneDeep(userData)
                  useInputs.samples = useInputs.samples.map(uis => {
                    const findSei = useInputs.samplingEventInputs?.find(sei => sei.id === uis.id)
                    return { ...uis, ...findSei || {} }
                  })

                  return (
                    <Grid container spacing={1} key={ss.header}>

                      <Grid item xs={12} md={12} className="formHeaderContainer" justifyContent='center'>{useIcon ? useIcon.component : null}<Typography component='p' variant='p'>{ss.header}</Typography></Grid>
                      <Grid item xs={12} md={12}>
                        <Grid container spacing={3}>

                          <InputObjRender
                            checkRequirements={checkRequirements}
                            sourceDataObj={ss}
                            setUserInput={setUserData}
                            objKey={ss.key}
                            data={data}
                            userInput={useInputs}
                            disabled={userData.status === 'sampling-complete' || userData.status === 'saved' ? 'force' : false}
                            handleInput={sampleInput}
                            sampleIndex={sample.id}
                          />
                        </Grid>
                      </Grid>

                    </Grid>
                  )
                })}
                <div style={{ margin: 'auto', paddingLeft: '15px' }}>
                  <BottlesForm checkRequirements={checkRequirements} refSample={sample} data={data} userInput={userData} setUserInput={setUserData} disabled={userData.status === 'sampling-complete' || userData.status === 'saved'} />
                </div>
              </Grid>
            )
          })}

        </Grid>
      </Paper>

      <Paper elevation={3} style={{ marginTop: '15px', padding: '15px', backgroundColor: 'rgba(240,240,240,0.8)' }}>
        {!userData.status !== 'sampling-complete' ?
          <>
            <TextField
              style={{ width: '50%' }}
              InputLabelProps={{
                shrink: true,
              }}
              label={<span>Name<span style={{ color: "red" }}>*</span></span>}
              error={checkRequirements && !userData.samplingPerson?.name}
              size="small"
              disabled={samplingComplete || userData.status === 'saved'}
              value={userData.samplingPerson?.name || ''}
              onChange={(e) => handleGeneralSamplingInput(e, 'name')}
            />

            <TextField
              style={{ width: '50%' }}
              InputLabelProps={{
                shrink: true,
              }}
              label={<span>Samples Tempurature<span style={{ color: "red" }}> (at sampler discretion)</span></span>}
              error={false}
              size="small"
              disabled={samplingComplete || userData.status === 'saved'}
              value={userData.samplingPerson?.tempurature || ''}
              onChange={(e) => handleGeneralSamplingInput(e, 'tempurature')}
            />

            <Button disabled={samplingComplete || userData.status === 'saved' || !userData.samplingPerson?.name || loadingLocal} style={{ width: '100%', height: '40px', marginTop: '5px' }} variant="contained" color="success" onClick={() => completesampling('sampling-complete')} >Complete Sampling</Button>

            {checkRequirements && !userData.samplingPerson?.name ? <FormHelperText style={{ color: 'red' }}>Required Entry</FormHelperText> : null}

            <p>An email will be sent when sampling is complete</p>
            <Button variant='outlined' onClick={() => { saveData(false, false) }} disabled={!changeDetected}>Save for Later</Button>
            {
              submissionError &&
              <Alert style={{ textAlign: 'left', marginTop: '15px' }} severity="error">Saving Failed, could not connect to database! Try again. Contact IT if issue persists.</Alert>
            }
            {
              entryErrors.length > 0 &&
              <Alert style={{ textAlign: 'left', marginTop: '15px' }} severity="error">
                There are errors or missing information in the form(s) above (see below for more detail). Please resolve these before continuing.
                <ul style={{ textAlign: 'left' }}>
                  {entryErrors.map((er, i) => {
                    return <li key={er + '-' + i} >{er}</li>
                  })}
                </ul>
              </Alert>
            }
            {loadingLocal ? <>Committing...<br /><LinearProgress /></> : null}
          </>
          :
          <>
            <Typography>sampling Complete</Typography>
            <Typography>{userData.samplingPerson?.name}</Typography>
            <Typography>{userData.samplingPerson?.date}</Typography>
          </>
        }
      </Paper>
    </>
  )

}

export default GeneralSamplingEventWorksheets;
